import * as React from 'react'
import Layout from '../components/layout'
import { FormContacto } from '../components/contacto/FormContacto'
import { RedesSociales } from '../components/contacto/RedesSociales'
import {Titulo} from '../components/titulo'
import  Map from '../components/map'
import { Container, Row, Col } from 'react-bootstrap';
const contacto = () => {
    
    return (
        <Layout>
            <Titulo title="Contacto" />
            <Container>
                <Row>
                    <Col xs={12} md={6}>
                        <FormContacto />
                    </Col>
                    <Col xs={12} md={6}>
                        <Map 
                            googleMapURL= {"https://maps.googleapis.com/maps/api/js?v=3.exp&key=AIzaSyCFKx6aEj60gkan8oW1S0-DO1XURknlnjE"}
                            containerElement={ <div style={{ height: `400px` }} />}
                            mapElement= {<div style={{ height: `100%` }} />}
                            loadingElement= {<p>Cargando</p>}
                            labelStyle={{backgroundColor: "yellow", fontSize: "32px", padding: "16px"}}
                        />
                    </Col>

                    <Col xs={12} md={12} className="mt-5 mb-5">
                        <RedesSociales />
                    </Col>
                </Row>

            </Container>
        </Layout>
    )
}

export default contacto
