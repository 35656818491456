import React, { useState } from 'react'
import { Form, Row, Col, Card } from 'react-bootstrap'
import Button from 'react-bootstrap/Button'
import { useDispatch } from 'react-redux'
import { crearContacto } from '../../action/contacto';
import { useForm } from '../../hooks/useForm';

export const FormContacto = () => {
    const dispatch = useDispatch()
    const [validated, setValidated] = useState(false);
    const [ contacto, handleInputChange, reset] = useForm({
        nombre: '',
        email: '',
        asunto: '',
        telefono: '',
        mensaje: '',
    });
    const { nombre, email, asunto, telefono, mensaje } = contacto
    const handleSubmit = (event) => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        setValidated(true);
        if(nombre!==''&&email!==''&&asunto!==''&&telefono!==''){
            dispatch(crearContacto(contacto))
            reset()
            setValidated(false);
        }
        
      };
    

    return (
        <Card className="bg-dark">
            <Card.Body>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <h4>Hola, necesitas ayuda?</h4>
                    <Row>
                        <Col md={4}>
                            <Form.Group controlId="formContactoNombre" >
                                <Form.Label>Nombre</Form.Label>
                                <Form.Control 
                                    type="text" 
                                    required
                                    placeholder="Ingrese su nombre"  
                                    name="nombre"
                                    onChange={handleInputChange}
                                    value={nombre}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group controlId="formContactoEmail">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email" 
                                    placeholder="Ingrese su email"
                                    required
                                    name="email"
                                    onChange={handleInputChange}
                                    value={email}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Form.Group controlId="formContactoAsunto">
                                <Form.Label>Asunto</Form.Label>
                                <Form.Control 
                                    type="text"
                                    required  
                                    name="asunto"
                                    onChange={handleInputChange}
                                    value={asunto}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Form.Group controlId="formContactoTelefono">
                                <Form.Label>Telefono</Form.Label>
                                <Form.Control
                                    type="tel" 
                                    name="telefono"
                                    required
                                    // pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                                    onChange={handleInputChange}
                                    value={telefono} 
                                />
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group controlId="exampleForm.ControlTextarea1">
                                <Form.Label>Mensaje</Form.Label>
                                <Form.Control 
                                    as="textarea" 
                                    rows={3} 
                                    name="mensaje"
                                    onChange={handleInputChange}
                                    value={mensaje}
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4}>
                            <Button type="submit" className="btn btn-primary btn-Block">Enviar</Button>
                        </Col>
                    </Row>
                </Form>
            </Card.Body>
        </Card>
        
    )
}
