import React from 'react'
import {
    GoogleMap,
    withScriptjs,
    withGoogleMap,
    Marker
} from 'react-google-maps';

const Map = (props) => {
    return (
        <GoogleMap 
            defaultZoom={16}    
            defaultCenter={{ lat: -29.8774096  , lng:-71.239498 }}  
        >
            <Marker
                position={{ lat: -29.8774096  , lng:-71.239498 }}  
                label={'Colegio Cielos del Valle'}
            />
        </GoogleMap>
    )
}

export default withScriptjs(
    withGoogleMap(
        Map
    )
)