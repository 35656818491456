import Swal from 'sweetalert2'
import { fetchSinToken } from "../helpers/fetch";

export const crearContacto = (contacto) => {
    return async(dispatch) => {
        try {
            const resp = await fetchSinToken('contactos', contacto, 'POST');
            const body = await resp.json();
            console.log(body)
            if(body){
                Swal.fire({
                    icon: 'success',
                    title: 'Mensaje enviado',
                    showConfirmButton: true,
                    timer: 1500
                })
            } else {
                Swal.fire('Ups...',body.msg,'error');
            }
        } catch (error) {
            console.log(error)
        }
    }
};