import React from 'react'
import { Row, Col } from 'react-bootstrap';
import maps from '../../images/enlaces/maps.svg'
import facebook from '../../images/enlaces/facebook.svg'
import whatsapp from '../../images/enlaces/whatsapp.svg'

export const RedesSociales = () => {
    return (
        <Row className="justify-content-md-center">
            <Col md={3} className="pointer" >
                <a 
                    href="https://www.google.cl/maps/place/Colegio+Cielos+Del+Valle+La+Serena/@-29.877438,-71.2415298,17z/data=!3m1!4b1!4m5!3m4!1s0x9691b576184760b9:0x9cfe9590ce079e8b!8m2!3d-29.8774362!4d-71.2393405" 
                    className="d-flex justify-content-center align-items-center text-decoration-none text-light" 
                    target="_blank" 
                    rel="noreferrer"
                >
                    <img
                        src={maps}
                        alt='maps' 
                        height={60}
                    /> 
                    <h4 className="ml-1">Maps</h4>
                </a>
            </Col>
            <Col md={3} className="pointer">
                <a 
                    href="https://api.whatsapp.com/send?phone=56954064360" 
                    className="d-flex justify-content-center align-items-center text-decoration-none text-light" 
                    target="_blank" 
                    rel="noreferrer"
                >
                    <img
                        src={whatsapp}
                        alt='whatsapp' 
                        height={60}
                    /> 
                    <h4 className="ml-1">WhatsApp</h4>
                </a>
            </Col>
            <Col md={3} className="pointer">
                <a 
                    href="https://www.facebook.com/Establecimiento-Cielos-Del-Valle-La-Serena-106124776659910" 
                    className="d-flex justify-content-center align-items-center text-decoration-none text-light" 
                    target="_blank" 
                    rel="noreferrer"
                >
                    <img
                        src={facebook}
                        alt='facebook' 
                        height={60}
                    /> 
                    <h4 className="ml-1">Facebook</h4>
                </a>
            </Col>
        </Row>
    )
}
